import {
  Checkbox,
  Col,
  Icon,
  Icons,
  Row,
  Skeleton,
  Spin,
  Table,
} from "@pankod/refine-antd";
import { IResourceComponentsProps, useCustom } from "@pankod/refine-core";
import DownloadMenu from "components/DownloadDropdown";
import dayjs from "dayjs";
import ENV from "utils/env";
import {
  exportToCsv,
  exportToExcel,
  exportToJson,
} from "utils/utiltyFunctions";
import { useState, useEffect } from "react";
import { useDashboard } from "dashboardProvider";

export const TableAttendedUsers: React.FC<IResourceComponentsProps> = () => {
  const { selectedDashboard } = useDashboard();
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    "fullName",
    "gsmNumber",
    "address",
    "district",
    "city",
    "attended_date",
    "shopInfo",
    "totalParticipation",
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  const {
    data: response,
    isFetching,
    isError,
    refetch,
  } = useCustom({
    url: `${ENV.API_URL}/api/campaign/lottery/attendedUsers`,
    method: "get",
    config: {
      query: {
        dbkey: selectedDashboard,
        limit: pageSize,
        page: currentPage,
      },
    },
    queryOptions: {
      retry: 1,
    },
  });

  const {
    data: responseExportData,
    isFetching: isFetchingExportData,
    isError: isErrorExportData,
  } = useCustom({
    url: `${ENV.API_URL}/api/campaign/lottery/exportData`,
    method: "get",
    config: {
      query: {
        dbkey: selectedDashboard,
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [currentPage, refetch]);

  if (isFetching) {
    return <Skeleton />;
  }
  if (isError) {
    return (
      <span style={{ fontWeight: 800, fontSize: 20 }}>Veri bulunamadı.</span>
    );
  }

  const dataSource = response.data.users.map((item: any) => ({
    key: new Date(item.createdAt).getTime(),
    gsmNumber: item["gsmNumber"],
    fullName: item.formData["name"] + " " + item.formData["surname"],
    address: item.formData["address"],
    district: item.formData["district"],
    city: item.formData["city"],
    score: item["score"],
    attended_date: dayjs(item["createdAt"]).format("DD/MM/YYYY HH:mm"),
    shopInfo: item.shopInfo["name"],
    totalParticipation: item.totalParticipation,
  }));

  const attendedUsersTableColumns = [
    {
      title: "Ad Soyad",
      dataIndex: "fullName",
      key: "fullName",
      width: "25%",
    },
    {
      title: "Telefon Numarası",
      dataIndex: "gsmNumber",
      key: "gsmNumber",
      width: "25%",
    },
    {
      title: "Adres",
      dataIndex: "address",
      key: "address",
      width: "25%",
    },
    {
      title: "İlçe",
      dataIndex: "district",
      key: "district",
      width: "25%",
    },
    {
      title: "İl",
      dataIndex: "city",
      key: "city",
      width: "25%",
    },
    {
      title: "Katılım Tarihi",
      dataIndex: "attended_date",
      key: "attended_date",
    },
    {
      title: "Oynandığı Mağaza",
      dataIndex: "shopInfo",
      key: "shopInfo",
    },
    {
      title: "Toplam Katılım Sayısı",
      dataIndex: "totalParticipation",
      key: "totalParticipation",
    },
  ];

  const attendedUsersTableColumnsForExport = attendedUsersTableColumns
    .filter((column) => selectedColumns.includes(column.dataIndex))
    .map((column) => ({
      header: column.title,
      key: column.dataIndex,
    }));

  const updatedAt = response.data.updatedAt;
  const formattedDate = updatedAt
    ? dayjs(updatedAt).format("DD-MM-YYYY-HH-mm")
    : "";

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const handleColumnSelectionChange = (checkedValues) => {
    setSelectedColumns(checkedValues);
  };
  const antIcon = (
    <Icons.LoadingOutlined type="loading" style={{ fontSize: 24 }} spin />
  );
  return (
    <div>
      <Row justify="end" gutter={16}>
        <Col>
          <Checkbox.Group
            options={attendedUsersTableColumns.map((col) => ({
              label: col.title,
              value: col.dataIndex,
            }))}
            defaultValue={selectedColumns}
            onChange={handleColumnSelectionChange}
          />
        </Col>

        {isFetchingExportData ? (
          <Col>
            <Spin indicator={antIcon}>
              <span>Veriler dışarı aktarmaya hazırlanıyor...</span>
            </Spin>
          </Col>
        ) : isErrorExportData ? (
          <Col>
            <span style={{ color: "red", fontWeight: "bold" }}>
              Veri dışarı aktarılamıyor.Lütfen daha sonra tekrar deneyiniz.
            </span>
          </Col>
        ) : (
          <Col>
            <DownloadMenu
              dataSource={responseExportData.data}
              columns={attendedUsersTableColumnsForExport}
              formattedDate={
                formattedDate || dayjs(new Date()).format("DD-MM-YYYY-HH-mm")
              }
              exportToExcel={exportToExcel}
              exportToCsv={exportToCsv}
              exportToJson={exportToJson}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col span={6}>Son Güncelleme Tarihi:</Col>
        <Col span={6}>
          {dayjs(response.data.updatedAt).format("DD/MM/YYYY HH:mm")}
        </Col>
        <Col span={6} style={{ fontWeight: 600 }}>
          Veriler her 5 dakikada bir güncellenmektedir.
        </Col>
        <Col span={12} />
      </Row>
      <Table
        dataSource={dataSource}
        columns={attendedUsersTableColumns.filter((column) =>
          selectedColumns.includes(column.dataIndex)
        )}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: response.data.totalCount,
        }}
        onChange={handleTableChange}
        scroll={{ x: 1500 }}
      />
    </div>
  );
};
