import { authProvider } from "authProvider";

export const accessControlProvider = {
  can: async ({ resource, action }) => {
    // Kullanıcı yetkilerini kontrol edin
    const user = await authProvider.getUserIdentity();

    // Eğer kullanıcı bilgisi yoksa erişimi reddedin
    if (!user) {
      return { can: false };
    }

    if (
      resource === "contents" &&
      (action === "list" ||
        action === "create" ||
        action === "edit" ||
        action === "delete")
    ) {
      if (
        (user.role.type === "shop-manager" ||
          user.role.type === "sales-manager") &&
        user.ommaShops.length <= 0
      ) {
        return { can: false };
      }
    }
    return { can: true };
  },
};
