import { ErrorComponent, HttpError, useCan } from "@pankod/refine-core";
import { Result } from "antd";

export const CustomErrorComponent = ({ error }: { error?: HttpError }) => {
  const { data: canAccessContents } = useCan({
    resource: "contents",
    action: "list",
  });
  if (canAccessContents && canAccessContents.can === false) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="İçerik yönetimi kısmını görmeniz için bir bayinizin olması lazım."
      />
    );
  }

  return <ErrorComponent></ErrorComponent>;
};
